
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

interface Filter {
  status: string;
  code: string;
}

export default defineComponent({
  name: "category",

  data() {
    return {
      categorys: [{ brand: { cmp: {} } }],
      categoryData: { brand: { cmp: {} } },
      searchcode: "brand_nm",
      searchdata: "",
      status: "",
      page: 1
    };
  },
  components: {
    VPagination
  },

  created() {
    this.getListData();
  },

  methods: {
    getSearchResult() {
      this.page = 1;
      this.getListData();
    },

    getListData() {
      const param = {
        [this.searchcode]: this.searchdata,
        page: this.page
      };
      base
        .getCategoryList(param)
        .then(res => {
          console.log(res);
          this.categorys = res.data;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    getData(id) {
      base
        .getCategory(id)
        .then(res => {
          console.log(res);
          this.categoryData = res.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    async editData() {
      base
        .updateCategory(this.categoryData)
        .then(res => {
          console.log(res);
          if (res.status == 200) {
            alert("카테고리 정보를 수정했습니다.");
            this.modalopen = false;
            window.location.reload();
          } else {
            alert(res.status);
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    }
  },

  setup() {
    const data = ref<Filter>({
      status: "1",
      code: "1"
    });

    return {
      data
    };
  }
});
